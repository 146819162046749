import { PanelOptionsEditorBuilder, SelectableValue } from '@grafana/data';
// import { getBackendSrv } from '@grafana/runtime';
import { Button, Collapse, Field, Input, RadioButtonGroup, Select, TextArea } from '@grafana/ui';
import React from 'react';
import { ButtonOptions, Options, QueryParams, ParamType } from 'types';

export interface EditorProps {
  buttons: ButtonOptions[];
  onChange: (buttons: ButtonOptions[]) => void;
  // onParamChange: (params: QueryParams[]) => void;
}

export const Editor: React.FC<EditorProps> = ({ buttons, onChange }) => {
  const [isOpen, setOpen] = React.useState<boolean[]>(buttons.map((e) => false));
  const [cacheButtons, setCacheButtons] = React.useState<ButtonOptions[]>(buttons);

  const updateCacheButtons = (index: number, newButton: ButtonOptions) => {
    let currentButton = { ...cacheButtons[index] };
    setCacheButtons([
      ...cacheButtons.slice(0, index),
      {
        text: newButton.text || currentButton.text,
        url: newButton.url || currentButton.url,
        variant: newButton.variant || currentButton.variant,
        params: newButton.params || currentButton.params,
        type: newButton.type || currentButton.type,
      },
      ...cacheButtons.slice(index + 1),
    ]);
  };

  return (
    <React.Fragment>
      {cacheButtons.map((b: ButtonOptions, i: number) => (
        <Collapse
          key={i}
          label={'Button ' + (i + 1).toString()}
          isOpen={isOpen[i]}
          collapsible
          onToggle={() => {
            setOpen((open) => [...open.slice(0, i), !open[i], ...open.slice(i + 1)]);
          }}
        >
          <Field label="Button Text">
            <Input
              id={'t-' + i.toString()}
              value={b.text}
              placeholder="Button"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateCacheButtons(i, { text: e.target.value, params: b.params })}
            />
          </Field>
          <Field label="Request url" description="url for request">
            <Input
              id={'u-' + i.toString()}
              value={b.url}
              placeholder="http://100.124.26.72:8001/test"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateCacheButtons(i, { url: e.target.value, params: b.params })}
            />
          </Field>
          <Field label="Query Parameters">
            <div>
              {b.params.map((q: QueryParams, queryIndex: number) => (
                <div style={{display: 'flex'}}>
                  <Input
                    id={'qp-' + queryIndex.toString()}
                    value={q.key}
                    placeholder="key"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateCacheButtons(i, { params: updateQueryParams(queryIndex, b, e.target.value, "key")})}
                  />
                  <Input
                    id={'qv-' + i.toString()}
                    value={q.value}
                    placeholder="value"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateCacheButtons(i, { params: updateQueryParams(queryIndex, b, e.target.value, "value")})}
                  />
              </div>
            ))}
            </div>
          </Field>
          <Field>
            <Button
              variant="secondary"
              icon="plus"
              size="sm"
              onClick={() => {
                let updateParams = buttons[i].params;
                updateParams.push({key: "", value: ""});
                updateCacheButtons(i, { params: updateParams})
              }}
            >
              Add Params
            </Button>
          </Field>
          <Field label="QueryType" description="Type of request">
            <RadioButtonGroup
              options={[
                { label: 'Get', value: 'GET' },
                { label: 'Post', value: 'POST' },
                { label: 'Delete', value: 'DELETE' },
                { label: 'Update', value: 'UPDATE' },
              ]}
              value={b.type || 'GET'}
              fullWidth
              onChange={(e: any) => {
                updateCacheButtons(i, { type: e, params: b.params })}
              }
            ></RadioButtonGroup>
          </Field>
          <Field label="Color" description="Color of the button">
            <RadioButtonGroup
              options={[
                { label: 'Primary', value: 'primary' },
                { label: 'Secondary', value: 'secondary' },
                { label: 'Destructive', value: 'destructive' },
                { label: 'Link', value: 'link' },
              ]}
              value={b.variant || 'primary'}
              fullWidth
              onChange={(e: any) => updateCacheButtons(i, { variant: e, params: b.params })}
            ></RadioButtonGroup>
          </Field>
          <Field>
            <>
              <Button
                icon="trash-alt"
                variant="destructive"
                onClick={() => onChange([...buttons.slice(0, i), ...buttons.slice(i + 1)])}
              >
                Delete
              </Button>
              <Button icon="save" variant="primary" onClick={() => onChange(cacheButtons)}>
                Apply
              </Button>
            </>
          </Field>
        </Collapse>
      ))}
      <Field>
        <Button
          variant="secondary"
          icon="plus"
          size="sm"
          onClick={() => {
            onChange([...buttons, { text: '', url: "http://100.124.26.72:8001/test", params: [{key: "", value: ""}] }]);
          }}
        >
          Add Button
        </Button>
      </Field>
    </React.Fragment>
  );
};

function updateQueryParams(index: number, button: ButtonOptions, param: string, type: ParamType): QueryParams[] {
  let params = button.params[index];

  if (type == "value" ) {
    params.value = param;
  } else {
    params.key = param
  }
  button.params[index] = params;
  
  return button.params
}

export function addEditor(builder: PanelOptionsEditorBuilder<Options>) {
  builder
    .addRadio({
      path: 'orientation',
      name: 'Orientation',
      description: 'Stacking direction in case of multiple buttons',
      defaultValue: 'horizontal',
      settings: {
        options: [
          { value: 'horizontal', label: 'Horizontal' },
          { value: 'vertical', label: 'Vertical' },
        ],
      },
    })
    .addCustomEditor({
      id: 'buttons',
      path: 'buttons',
      name: 'Button Configuration',
      defaultValue: [{ text: '', datasource: '', query: '', params: [] }],
      editor: (props) => <Editor buttons={props.value} onChange={props.onChange} />,
    });
}

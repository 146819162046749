import { AppEvents, InterpolateFunction, PanelProps } from '@grafana/data';
import { getAppEvents, getBackendSrv, getDataSourceSrv, getTemplateSrv, } from '@grafana/runtime';
import { Button, HorizontalGroup, VerticalGroup } from '@grafana/ui';
import React, { useEffect, useState } from 'react';
import { ButtonOptions, Options, QueryParams, QueryType } from 'types';

interface Props extends PanelProps<Options> {}

async function postQuery(button: ButtonOptions) {
  let url: string = button.url ? button.url : "none";
  let params = "";

  let type = button.type!;

  let requestOptions = {};
  let data: Record<string, Record<string, string>> = {
    "data": {

    },
  };

  if (type == 'POST') {
    
    button.params.map((param: QueryParams) => {

      let value = param.value;
  
      if (value.startsWith("$")) {
        value = getTemplateSrv().replace(value);
      }

      data["data"][param.key] = value
    })

    // console.log(type + " request" + " data is: " + data);

    requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  } else if (type == 'GET') {
    // console.log(type + " request")
    requestOptions = {
      method: "GET",
    }
    button.params.map((param: QueryParams) => {

      let value = param.value;
  
      if (value.startsWith("$")) {
        value = getTemplateSrv().replace(value);
      }
      params += param.key + "=" + value + "&";
    });
    
    url = url + "?" + params;
  }
  const appEvents = getAppEvents();

  // console.log(`url => ${url}`);
  // console.log(`params => `, params);

  try {
    let data = await fetch(url, requestOptions);

    if (!data.ok) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [data.toString()],
      });
    } 

    let test = await data.json();

    let parsed = "";

    try {
      parsed = test.text.data.data.toString()
    } catch (e) {
      // for get requests
      parsed = test.text;
      console.log(`${e} ${test.text}`)
    }

    appEvents.publish({
      type: AppEvents.alertSuccess.name,
      payload: [parsed + " : " + data.status.toString()],
    });

  } catch (error: any) {
    appEvents.publish({
      type: AppEvents.alertError.name,
      payload: [error],
    });
  }
}

export const ButtonPanel: React.FC<Props> = ({ options, replaceVariables }) => {

  const renderButtons = (buttons: ButtonOptions[]) => {
    return buttons.map((b: ButtonOptions, index: number) => {

      let text = "";

      if (b.text?.startsWith('$')) {
        text = getTemplateSrv().replace(b.text);
      } else {
        text = b.text || 'Send Request';
      }

      return (
        <Button key={index} variant={b.variant} onClick={async () => postQuery(b)}>
          {text}
        </Button>
      );
    });
  };

  return (
    (options.orientation === 'vertical' && (
      <VerticalGroup justify="center" align="center">
        {renderButtons(options.buttons)}
      </VerticalGroup>
    )) || (
      <HorizontalGroup justify="center" align="center">
        {renderButtons(options.buttons)}
      </HorizontalGroup>
    )
  );
};


// http://100.124.26.72:8001/test